import React from "react";
import styled from "styled-components";

const StyledGitMark = styled.button`
  position: absolute;
  bottom: 2.3vh;
  left: 1.8vw;
  border-width: 0;
  /* box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.5); */
  width: 4vw;
  height: 6vh;
  padding: 0;
  background: rgba(255, 255, 255, 0.1);
  & #repo {
    opacity: 0;
  }
  &:hover {
    background: white;
  }
  & a {
    width: 100%;
  }
  & svg {
    width: 100%;
    height: 100%;
    &:hover {
      & #repo {
        animation: fadeIn 0.5s ease-in-out forwards;
      }
    }
  }
`;
const GitMark = () => (
  <StyledGitMark className="git-mark-btn">
    <a href="https://github.com/beauhaus/Nephele#readme">
      <svg viewBox="0 0 50 50">
        <path
          fill="#191717"
          fillRule="evenodd"
          d="M25 2.5c-9 0-16.3 7.3-16.3 16.3 0 7.2 4.7 13.3 11.1 15.5.8.1 1.1-.4 1.1-.8v-2.8c-4.5 1-5.5-2.2-5.5-2.2-.7-1.9-1.8-2.4-1.8-2.4-1.5-1 .1-1 .1-1 1.6.1 2.5 1.7 2.5 1.7 1.5 2.5 3.8 1.8 4.7 1.4.1-1.1.6-1.8 1-2.2-3.6-.4-7.4-1.8-7.4-8.1 0-1.8.6-3.2 1.7-4.4-.2-.4-.7-2.1.2-4.3 0 0 1.4-.4 4.5 1.7 1.3-.4 2.7-.5 4.1-.5 1.4 0 2.8.2 4.1.5 3.1-2.1 4.5-1.7 4.5-1.7.9 2.2.3 3.9.2 4.3 1 1.1 1.7 2.6 1.7 4.4 0 6.3-3.8 7.6-7.4 8 .6.5 1.1 1.5 1.1 3v4.5c0 .4.3.9 1.1.8 6.5-2.2 11.1-8.3 11.1-15.5C41.3 9.8 34 2.5 25 2.5z"
          clipRule="evenodd"
        />
        <g id="repo">
          <path d="M8.6 47.4l-2.3-2.7H3.8v2.7H1.1v-9.8h5.1c1 0 1.9.1 2.7.4.8.3 1.3.7 1.8 1.2s.6 1.2.6 1.9-.2 1.4-.6 1.9-1 .9-1.8 1.2l2.6 3.2H8.6zm-.7-7.6c-.4-.3-1-.4-1.9-.4H3.8v3.4H6c.8 0 1.4-.1 1.9-.4.4-.3.6-.7.6-1.3 0-.5-.2-1-.6-1.3zM22.4 45.5v1.8h-9v-9.8h8.8v1.8h-6.1v2.1h5.4v1.8h-5.4v2.3h6.3zM32.4 38c.8.3 1.3.7 1.8 1.2.4.5.6 1.2.6 1.9s-.2 1.3-.6 1.9c-.4.5-1 .9-1.8 1.2-.8.3-1.7.4-2.7.4h-2.4v2.7h-2.7v-9.8h5.1c1 .1 1.9.2 2.7.5zm-1 4.4c.4-.3.6-.7.6-1.3 0-.6-.2-1-.6-1.3s-1-.4-1.9-.4h-2.2v3.4h2.2c.8 0 1.4-.1 1.9-.4zM39.2 46.9c-1-.4-1.7-1-2.3-1.8-.6-.8-.8-1.6-.8-2.6s.3-1.8.8-2.6c.6-.8 1.3-1.4 2.3-1.8 1-.4 2.1-.7 3.3-.7s2.3.2 3.3.7c1 .4 1.7 1 2.3 1.8.6.8.8 1.6.8 2.6s-.3 1.8-.8 2.6c-.6.8-1.3 1.4-2.3 1.8-1 .4-2.1.7-3.3.7s-2.3-.3-3.3-.7zm5.1-1.7c.6-.3 1-.6 1.3-1.1.3-.5.5-1 .5-1.6 0-.6-.2-1.1-.5-1.6s-.8-.8-1.3-1.1c-.6-.3-1.2-.4-1.9-.4s-1.3.1-1.9.4c-.6.3-1 .6-1.3 1.1-.3.5-.5 1-.5 1.6 0 .6.2 1.1.5 1.6s.8.8 1.3 1.1 1.2.4 1.9.4c.8 0 1.4-.1 1.9-.4z" />
        </g>
      </svg>
    </a>
  </StyledGitMark>
);

export default GitMark;
