import React from "react";
import styled from "styled-components";

const StyledZeus = styled.div`
  position: absolute;
  top: 2vh;
  left: 2.2vw;
  border-width: 0;
  width: 4.5vw;
  height: 9vh;
  padding: 0;
  & svg {
      width: 100%;
      height: 100%;
  }
`;
const Zeus = () => (
  <StyledZeus className="zeus-container">
  <svg viewBox="4.5 0 186 200">
  <path fill="#D69148" d="M141.2 118.7c.7.6.6 1.3 0 2.2-3 1.7-6.7.6-9.7 2.2-.2.2-.4.1-.6.2 3.1-1.6 6.7-.9 10-2.1.7-.4 1-1.6 2.1-1 .9.7 2 1.2 3.1 1.4.4.1.6.3.9.5.2.2.5.4.8.6l.4.4c.2.3.4.6.5.8.1.4.2.6.2 1-.2 2.9-2.3 3.8-5.1 4-2.5.1-4.7-.7-7-1.1-4.7-.8-9.2 3.4-8.3 7.7.2.3.3.6.4.9 0 .3.1.6 0 1-.1.3-.2.6-.4.9-2 1.6-4 1.8-5.8-.2-.3-.8-.4-1.7-1.2-2.1-3.2-1.6-2.6-3.8-1.1-6.1.2-.7.6-1.2 1.2-1.6.1-.1 0-.2-.3.2-.5.4-.8 1.1-1.1 1.7-1.3 2.5-1.7 4.8 1.5 6.4.9.5 1 1.6 1.7 2.3 1.8 2 2.2 4 .1 6.2-1.6-1.5-1.3-3.2-1.6-4.7.2 1.9.3 3.9 1.4 5.7.2 3.3-.7 6.4-1.2 9.6-.2 1.3-.2 2.8-.9 4-.6.8-1.1 2-2.5.7l-.2-.3-.3.1c-.1.2-.2.4-.4.6-.6.6-1.1 1.1-2.1 1-1.6-1.1-1.1-2.8-1.5-4.2.1.9.3 1.9.2 2.9-.1.3-.3.6-.6.8-.4.1-.7.2-1.1.2-1.2-.1-2-.7-2.3-1.8 0-1.7.5-3.4-.6-4.9-1.3-1-3-1.8-3.4-3.5-.4-1.8-.9-3.5-1.2-5.2l-.9-.4c-.5-.3-.8-.6-1.2-1.1-.2-.3-.4-.6-.5-.9-.2-.8-.5-1.6-.1-2.5 2.9-2.7 3.1-2.7 6 1.4-.7-1.9-2.8-2.8-3.1-4-12 .4-23.8-2.3-34.1-1.6 9.7 0 20.9 1.1 32.5 2-.7 1.4-1.6 2.3-1.7 3.5-.2.3-.6.4-1 .5-6.5-.3-13 .1-19.5-.7-2.9-.4-6-.4-9 .4-1.2.2-2.5.3-3.8.1-1.4-.4-2.8-.6-4.1-.8-.9-.4-1.9-.6-2.7-1.3-.9-1-1.4-2.1-.8-3.5.6-.7 1.3-.9 2.1-.2-2.3-1.9-.2-2.9 1-4 1.6-1.2 3.4-1.4 5.5-1.1 1.9.3 4.6.7 4.1-2.6-.8-1.3-.8-2.5.1-3.7.2-.6 1.1-.9.8-1.8-1.2-.7-2.4-.7-3.6.2-.4.5-.3 1 0 1.5 1.1 1.7 3.3 3 2.7 5.4-.8 1.1-2 1.3-3.3 1.1-3.4-.4-7-.7-9.7-3-1.3-4.2 1.7-7.3 3.3-10.7.5-1.1 2-.6 3.1-.6 1.6-.1 3.2.3 4.9-.1 4.8-1 8.1-4 11.5-7 .4-.4.8-.6 1.4-.7 2.2.4 2.9 2.3 4.2 3.7.5-.1 1.1-.6 2.1-1.9 4.7-2 9.5-1.1 14.3-1.1.6 0 1.1.5 1.5.9.6.6.9 1.6 2 1.8.8.1 1.5.2 2.4.2h1.5c.9 0 1.8.1 2.7.4 1.8 1.1 4.1 1.6 6 2.5 1.5 1.2-.1 4.3 3.2 4.6.1-1-.5-1.3-.8-1.8-.5-.8-1.1-1.6-.3-2.5.8-.8 1.9-.8 2.8-.4 3.7 1.9 7.7 1.3 11.6 1.8.9 0 1.9.3 2.9.6z"/>
  <path fill="#BB7637" d="M123.2 156.6c1.2 1.3 2.4 2.6 3.8 3.7.5 1 .7 2 1.4 2.9.2.3.3.6.4.8.2 1.7 1.3 2.9 3 3.7 1.7-1.8 1.4-4.7 3.8-5.9 2 .6 1.6 2.4 2.8 3.3 1.5-4.2 1.5-4.2 6.3-3 .9.3 2-.1 2.8.6-.5 4.2 2.3 9-2.1 12.7-.5.3-.8 0-1.1-.4-.1-.4-.1-.8.1-1.1.1-.1.2-.4.2-.6-.5-.1-.4.4-.5.6-.4.6-.8 1.3-1.5 1.7-4.8 2.4-9.7 5.6-15.2 1.5.3.8 1.2 1.4 2.1 2 3.5 2.3 3.1 5.9-.2 8.4-2.3 1.8-6 .8-7.5 3.6-2.2 1.1-4.6 1.1-7 1.1-3.5 0-5.2-1.7-4.5-4.9.5-2.5.5-4.9.5-7.3.1-2.8 1.1-5.4 2-8.1.7-3.1.7-6.1-1.3-8.9-.2-1.9 2-1.4 2.8-2.4v-7.5c.9 3.5 1.5 5.8 2.1 8.1.6-.4 1.4-.7 1.5-1.6v-.5c-.1 0-.2-.1-.3-.2-.1-.1 0-.4.2-.5.7.4 1.4.8 2.4 1.5.2-1.6.5-3 .8-4.4.8-.1 1.4.6 2.2 1.1z"/>
  <path fill="#CC8D4F" d="M126.6 74c.9.4 1.7.9 2.6 1.3.5.3 1.1.2 1.7 0 .5-.1.9 0 1.3.3.4 1.6 1.1 3.3-1.6 3.6-.2-.3-.5-.5-.8-.7-.3-.4-.7-.6-1.2-.7-3.9.4-9-.6-7.4 5.7.7.8-.2 1.3-.6 1.9-9.8 2.4-19.7 4.7-29.5 7.1-.8.2-1.7.4-2.5.4-.7.1-1.4.4-2.1.5-.8.1-1.5.1-2.3.2-4.9.8-9.8.8-14.7 1.1-2.1-1.8-4.9-3.3-2.7-6.6 3.2-1.1 5.5-3.7 8.7-4.9.2-.1.3-.6.5-.8.6-.8 1.6-1.1 2.6-1.3 7.3-.8 12.8-4.2 16.8-9.8.9-1.3 1.8-2.5 3.8-2.4 2.1.8 1.5 2.2.8 3.5-.8 1.3-1.8 2.5-2.6 3.8-.2.4-.4.6-.6.8-.5.6-1.1 1.1-1.8 1.7-.2.1-.2.2-.4.3-.2.1-.5.3-.8.4l-.3.1c-.1 0-.3.1-.3.1H93c0 .1.1.1.2.1.5.1-.2.1.2-.1.2 0 .3-.1.4-.1.1.1.2.1.4.1.2.1.5.4.7.6 2.2 1.1 4.6-.3 6.8.6 7-2.1 14.1-4.3 21.1-6.5 1.3-.5 2.6-.2 3.8-.3z"/>
  <path fill="#D89853" d="M138.3 166.3c-1.8-2.7-4.1-5.3-2.1-8.9 1.1-1.3 1.8-.5 2.5.4.5.6.8 1.1 1.5 1.6.1.1.2.1.2.1s-.2-.1-.1-.1c-.7-.4-1.1-1-1.5-1.6-.9-1.2-2.3-2.3-1-4 1.2-1 .6-2.8 1.9-3.7 1.5 0 2.8 1.2 4.1 1-1.2-.3-2.8-.4-3.7-1.7-.2-1.3.7-2.1.8-3.2.2-1.1 2.3-1.7.8-3.1.2-.4.2-.7-.4-.4-.4 0-.8-.2-1.1-.6-1.2-1.3-1.8-2.9-1.8-4.7 0-1.6 0-3.2-1.1-4.6-.2-.1-.1-.5-.4-.6-.1.4.4.5.5.8 1.1 1.1 1.1 2.6 1.1 4 .1 2.2.5 4.1 2.3 5.7 1.5.9 1.1 2 .5 3.2-.7.8-.4 2.2-2.3 2.3-4-3.7-2.8-9-3.9-13.2.8.6.4 2 .5 3.3.2 3 .3 6.1 2.2 8.4 1.9 2.5 1.7 4.7.4 7.1-1.2.4-1.6-.4-1.9-1.2-1.1-2.8-2.5-5.4-2.5-8.3-.4-.5-.5-1-.5-1.6 0-.2 0-.6-.1-.6-.5 0 0-.3.1.3-.2.8.5 1.6.8 2.4-.2 3.1 1.5 5.8 2.7 8.5.6 1.3 1.1 2.5.2 3.9-.8.4-.4 1.7-2.1 1.6-2.2-2.8-2-6.9-4.2-9.8-.8-3.7-2.8-7.3-2.2-11.3.6-.5 1-1.3 1.5-1.9-.5.2-.7.9-1.5.5-1.5-2.1-.8-4 .9-5.7 2.1-1.9 4.6-2 7.3-1.3 0 0 0 .1-.1.3-.2.4.6-.2.5-.2-1.8-.9-3.6.1-5.4-1.3 5.7-2.2 10.7 1.7 15.4.1 1.3 1.3.7 2.4.6 3.4-.2.9-.8 1.7-1.4 2.4-.2.6.2.6.5.8 1.6.4 3.1 1 2.8 3-.8 8.4-1.6 16.9-2.4 25.3-2.3-.1-4.6-.2-6.9-.4-.5.8-1.2 2.2-2 3.6z"/>
  <path fill="#CE813B" d="M25.4 82.8c2.9-3.8 6-7.6 8.8-11.4 3.2-4.5 6.5-9.1 9.7-13.6 1.8-.9 2.4-2.6 3.2-4.2.2-.3.5-.5.6-.8.8-.4 1.4.1 1.8.6.5 1.6-.5 2.9-.9 4.3-.2.6-.6 1.2-.6 1.8-.1.5-.2 1-.4 1.5-.5 1.7-1 3.3-1.8 4.9-3.2 6.9-6.7 13.7-10.3 20.5-2.3 4.2-5.8 8-7.1 12.8-.2.8-.2 1.7-.7 2.4-.2.3-.5.5-.9.6-.5.1-1.1-.1-1.6-.2-.4-.1-.7-.3-1-.4-2.9-1.6-5.5-3.8-9.2-3.7-.3-.3-.3-.6-.1-.8 3.6-4.9 7.1-9.5 10.5-14.3z"/>
  <path fill="#C38C59" d="M57.7 169c-.6-1.3-2.1-1.8-3.1-2.6 6.9-2.8 13.8-5.7 21.7-8.8-14.6-8.2-25-21.9-44.5-16.7-2.6.7-6.4-2.5-9.6-3.8.9-1.1 1.8-2 2.7-3.1 2.8 4.9 2.9 4 8.3 4 1.6-1.3 3.5-.1 5.2-.6 1.5-1.7 2.8-3.7 5.6-3.1 1.1 1.3-.8 2.3 0 3.5 1.1.8 2.3.6 3.5.4 1.1-.2 2.2-.6 3.4-.8.5 0 1 .1 1.5.3 1.8.6 3.5-1.2 5.3-.2.2.2.5.4.5.8-1.1 3.5 2.6 1.6 3.6 2.8.9 1.9 2.5 3.4 4.5 4.4.3.1.6.4.8.6 3.4 2.7 7.4 4.7 11.2 7.1.7.4 1.4.9 1.8 1.6.1.3.1.6 0 .8-.2.4-.4.7-.7 1-.2.2-.3.4-.5.5-.2.1-.2.3-.3.5 0 .2.1.4.2.5.3.2.6.4.8.6l.5.6c.2.4.2.8-.2 1.1-7.5 2.7-14.9 5.6-22.2 8.6z"/>
  <path fill="#A86934" d="M151.1 197.2c-1.5.6-1.9-.8-2.8-1.4-.1-.1-.2-.2-.3-.4-.2-.3-.4-.5-.5-.8-.2-.2-.3-.5-.5-.8-.3-.6-.8-1-1.2-1.5-2.2-2.5-5.1-3.1-8.4-2.1-4.5 1.3-9.2 1.1-13.9.8-.6-.2-1.1-.4-1.4-.9.2-1.1.2-2.1.4-3.5 1.6.9 2.7 1.5 4.1 2.3.5-2 1.4-3.5 3.4-3 3-5.9-5.1-6.4-6.4-13.7 6.9 8.7 12.6 5.2 18.4 2.5.7-.4 1.4-.5 2.1 0 .3.2.6.5.9.7.7 7.6 1.3 15.2 6.1 21.8z"/>
  <path fill="#070403" d="M100.7 41c-1.1-1.7-2.3-3.3-3.3-4.9-1.1-3.9-1-7.6 3-10.2 2.3-.8 4.4-.9 6.4.7 1.3 1.1 1.9 2.4 2.1 4 .4 1.6.7 3.3 1.7 4.7 1.8 3 .4 6 .2 9-.2 1.4-.2 2.8-.5 4.8-2.2-1.7-3.9-3-5.5-4.2-1.9-.7-3-2.3-4.1-3.9z"/>
  <path fill="#B67C4B" d="M132.1 96.8c.4.6 0 1-.5 1.3-1 .6-2.1.9-3.3.8-4.4-1.1-9 .1-13.5-.6 1.6.2 1.4 1.1 1 1.9 2.6-.1 5.3.5 7.9-.1 2.8-.1 5.6-.2 8.2.4 3.2.7 6.8-.8 9.7 1.6.2.9-.3 1.5-1.2 1.9-5.5.8-11.1.4-16.6.5-4.3.1-8.8.8-13-.8-1.1-.6-2.2-1.4-3.4-1.8-1.2-1.8-3-1.1-4.7-1-3.1.1-6.2.1-9.3 0-2.3-.1-4.7.6-6.9-.8-.7-2.3 1-1.9 2.4-1.9 5.1.1 10.3-.1 15.5 0-5.1-.1-10 .1-15.1 0-1.3 0-2.8-.1-2.9-1.6-.1-1.8 1.6-1.7 3-1.9 1.4-.2 2.6.9 4.1.4 12.7.4 25.7 1.1 38.6 1.7z"/>
  <path fill="#BD824D" d="M24.8 133.8c1.5-1.8 2.9-3.7 4.4-5.6-.1-.4 0-.6.3-.8.8-2.3 1.6-4.5 2.4-6.6.4-.8.4-1.7.9-2.4.8-.8.5-2 1.1-2.8.3-.1.6-.3.9-.4.5-.1 1.1 0 1.5.1 1.6.2 3.4.1 4.1 2 .4 1-.2 1.8-.9 2.5-3.6 3.6-3.6 3.9-.5 8.1-.9 3.9-2.5 7.5-5.7 10.3-1.8 3-4.1.4-5.9.3-2.7-.1-3.3-2.4-2.6-4.7z"/>
  <path fill="#080506" d="M115.9 7.9c-.2.4-.6.8-1.1 1-.3.1-.7.2-1 .2-1.3.1-2.5-.7-3.8-.1-.6.2-1.2.4-1.8.7-.5.2-.9.4-1.5.4-1-.1-1.9-.8-3-.4-.8.2-1.5.4-2.2.5-.3.1-.5.1-.8.1-.2 0-.5-.1-.8-.1-.4-.1-.8-.1-1.1.1-.2 0-.2.1-.4.1-.3.1-.5.3-.8.4-1.5.8-2.8 1.7-4.4 2-.4 0-.7.1-1.1.1-.6 0-1.1 0-1.7-.1-1.8-.5-2.2-1.8-2-3.3.8-.8.6-1.8 1.1-2.5 8.1-10 16.7-4.9 25.2-.6.6.3 1.2.7 1.2 1.5z"/>
  <path fill="#C28B55" d="M78.9 158.7c-.4-.4-1.1-.3-1.5-.7-.5-.8.3-1 .8-1.4.5-.4 1-.6 1.3-1.1l.2-.3c.5-.7 1.1-1.3 1.9-1.6 6.5-2.8 12.3-6.9 18.5-10.2.5-.2 1-.3 1.5-.4.9-.2 1.2.4 1.7.9.4.6.8 1.1 1.5 1.4.8.8.2 1.6-.1 2.3-4.7 4.2-11 6.2-15.9 10 1 1.8 3.1 2.3 3.6 4 .1 1.4-.5 2.3-2.1 2.7-3.2-1.4-6.5-2.8-9.7-4.2-.6-.6-1-1.2-1.7-1.4z"/>
  <path fill="#AD794B" d="M50.7 191.4c.3.3.5.6.8.8.2.3.5.6.8.8.2.2.2.4.1.6-.8.5.1 1.9-.9 2.2-1.8.1-1.5-1.6-2.4-2l.1.1c-.2-.4-.5-.6-.9-.8-6.2-11.8-3.1-24.8 6.3-26.8 1.1.1 2.1.2 3.1.3v2.5c-12.1 4.9-7.8 14.1-7 22.3z"/>
  <path fill="#451C0C" d="M100.9 26.6c-2.2-1.8-2.8-3.7-.8-6.1 1.8-2.9 5.4-2.5 8.1-3.7.4 0 .7 0 1.1.1 1.3.4 2.5.4 3.8-.2l.9-.3c1.5-.4 3-.6 4.4-1.1.5-.1 1-.1 1.5-.2 5.5-1 6.1-.8 7.6 2.2 0 .6-.1 1.1-.1 1.7-.2.1-.4.2-.5.4-1.4.5-2.8 1.1-4.1 1.6-3 1.6-6.7.4-9.6 2.3-2.5 1-4.4 3-7 4-1.7-.2-3.5-.4-5.3-.7z"/>
  <path fill="#A16A3A" d="M122.6 99.2c-1 .4-2.1.1-3.1.3 1.9-.1 3.9-.3 5.8-.7 3.3 0 6.7-.4 9.9.4-1.3-.7-3.3.5-4-1.6.2-.3.5-.5.8-.8 3.8 0 7.7.1 11.5.1 1.3.4 2.5.6 3.8-.1.3-.1.7-.1 1 0 .2-.1.4-.1.6-.1.5.4.5.8.4 1.3-.6 1.5-2.8.6-3.5 2 1 1.2 2.7.6 3.7 1.4 1.5 1.1 4.7.6 4.3 3.9-2.5.8-5.6-.6-7.8 1.3-.8.3-1.6.6-2.4.8-.4.1-.7.1-1.1 0-2.1-.8-4.5-.1-6.6-.9-.8-.4-1.6-.8-1.6-1.9 1.8-1.8 4.5.2 6.3-1.5.2-.5.5-.9.8-1.4-6-1.3-12-1.1-18.1-.8h-11.9v-1c1.3-.2 2.6-.4 4.4-.7-2.3-.4-4-.6-5.7-.8.1-.4.1-.8.2-1.2 6.1.3 12.2.6 18.2.9-1.9 1-4 .4-5.9 1.1z"/>
  <path fill="#89502A" d="M123.8 190.2c3.2.5 6.6.4 9.4 1.4 1.1.5 2.5.7 3 2 .9 2.3 3 2.5 5.3 2 .9-.2 1.9-.7 2.8.1.3.4.5.7.8 1.1.4.5.8.9.5 1.6h-15.2c-1.1-.8-3.2-.5-3.4-2.3-.2-2.5-2-2.4-3.8-2.3-4 .4-7.9 1-11.4 3-1.8-.4-3.4-1.1-3.9-2.9-.3-4.3 1.5-8.6.6-12.9-.2-.9.4-1.7 1.5-2.1.8-.1 1.1.4 1.5.8-.8 7.3-2.1 14.9 10.6 10.5h1.7z"/>
  <path fill="#170C09" d="M85.9 14.4c1.1-1.3 2.5-1.3 3.9-.4 1.5 1.6 3.1 3 5.4 3.8 1.6 1.5.7 2.9-.2 4.2-.3.4-.7.8-1.1 1.1-1.5.8-3.1 1.1-4.7 1.4-1.5.3-3.1-.3-4.1 1.3-.3.4-.7.7-1.1.9-.8.4-1.6.4-2.4 0-.3-.1-.5-.4-.7-.6-.5-.6-.5.3-.8.2-.2.1-.5.2-.8.3-.6.1-1.2.1-1.8-.1-.2-.1-.5-.3-.8-.4-2-4.2 1.8-5.4 4.4-7.3 1.5-1.5 3.4-2.7 4.8-4.4z"/>
  <path fill="#C4834E" d="M81 26c1.5 1.3.4 2.3-.3 3.4-.9 1.5-2.8 3-2.8 4.4.2 3.4-2 4.2-4.5 5-1.5-.4-3.3.8-4.7-.6-.3-1.3.5-2.2 1.5-3 1.3-.8 2.8-1.5 3.7-3.3-1.5.9-2.7 1.5-3.7 2.4-2 1.4-4.6 2-6.1 4-2 2.8-4.8 4.7-7.4 6.9-.8.7-1.7 1.8-3.1.9-.5-1.1.7-1.8.9-2.8 2.9-3.3 5.4-7.1 8.8-9.8 4-3 8.9-5 13.4-7.4.2-.1.5 0 .7.1.8.1 1.6.1 2.4 0 .2-.1.5-.1.8-.2.1-.3.4-.3.4 0z"/>
  <path fill="#BE8147" d="M128.1 113.9c-.8.7-2 0-2.8.6v.2c-.5 1.1-1.5 1.6-2.8 1.7-2.4-.6-4.8-.8-7.1-1.8-1.1 0-2.1-.1-3.1-.1-.5-.4-.9-.5-1.4 0l-2.3-.6c-.7-3 .5-4.8 3.4-5.2 2.9-.3 5.8-.3 8.5.8 1.3.6 2.6.7 4 .4.6-.1 1.1 0 1.8-.1 1.4-.4 2.8-.5 4.1-1.1 1.5-.4 3-1.8 4.8-.6 0 1.2-1 1.5-1.8 1.8 1.5-.2 2.5-1.5 3.9-1.9 1.8-.5 3.2-2 5.3-.8.3.1.5.1.8.2-5 2.1-10.1 4.3-15.3 6.5z"/>
  <path fill="#C87436" d="M168 75.9c-1.3 1.3-1 3.3-1.6 4.8v.1c-2.1.1-2.9-1.1-2.8-2.8.1-1.3.8-2.5 1.1-3.7l8.4-21c.5-.4.5-1 .8-1.4v-.1c3.8 0 1.5 2.4 1.7 3.7-2.3 6.3-4.7 12.6-7 18.9-.4.4-.3 1-.6 1.5z"/>
  <path fill="#6B3B1A" d="M33.3 118.5c-2.6-1.4-3.4-4.2-5.5-6.1-.5-.4-.8-.9-1.3-1.3-4.4-3.7-8.7-7.4-13.1-11.1 4.1-.6 6.2 2.5 9.1 4.1 1.5.8 2.8 2.3 4.7 2.3.2-.7-.1-1.1-.2-1.5-.6-1.1-.2-2.2.1-3.3.1-.9.2-1.8.2-2.7.4-.4.9-.5 1.5-.3 1.5 3.5 3.8 5.9 8.8 5.9-2.1 2.3-1.7 5.7-5.1 6.9.3 1.5 1.9 2.5 1.5 4.2.3 1.2 2.1 2.5-.7 2.9z"/>
  <path fill="#9E5629" d="M95.3 16.3c1.8-2.1 2.5-2.1 4.5.4 1.4.5 2.9.6 4.4.6-1.6-.1-3.3-.1-4.7-.9.2-2.8 2.8-2 4.5-2.3 1.7-.3 3.5.4 4.9-1.1.7-1.1 1.4-2 2.8-2 .5.1 1.1.2 1.5.4 2.9.4 5.8.6 8.7-.1 3.1-.1 5.7 1.4 8.7 1.3-1 1.6-2.1 3.3-3.1 4.9-2-3.1-5.1-.9-7.6-1.3-1.1-.8-2.5-.6-4.1-.9 1.1.4 2.1.1 2.7 1-1.6.4-3.2.8-4.8 1.1-1.5-.4-3.1-1.3-4.5.2-2.8 1.1-5.6 2.3-8.3 3.4-2.9-.5-4.1-2.8-5.6-4.7z"/>
  <path fill="#BE7B48" d="M148.9 96.7c8.5.5 8.4-6.6 11.5-11 1.3.9 1.8 2.3 2.4 3.5-4.3 6.9-2.8 18.4-16.8 17.4 1.2-3.9 5.4.4 7.4-2.3-1.5-.8-2.8-1.7-4.3-2.5-.5-.2 1.8-.8-.2-.8-1-.1-2.1.1-2.2-1.3 1.5-1.3 3.3-.5 4.8-.8-.8-.1-1.9.2-2.5-.8 0-.5-.1-.9-.1-1.4z"/>
  <path fill="#A46D48" d="M108.7 195c.2.4.4.8.4 1.2-.2 1.1-.8 2-2.1 2.3-2.5-2-6.4-3.7-7.3-6.2-1.6-5 5.9-3.8 7.4-7.1.4-4.2.8-8.6 1.7-12.7.3-1.3-.4-3.3 2.3-3.2.7.7.9 1.5.8 2.3-.1 2.5 0 5-1.2 7.4-.7 5.2-1.4 10.4-2.1 15.5.2.2.2.3.1.5z"/>
  <path fill="#C2884D" d="M98.7 168.6c-1.6-.9-2-2.2-1.4-3.7.2-.4.4-.6.8-.8.5-.2 1.1-.1 1.6.1.3.1.6.2.9.4 1.1.6 1.9 1.8 3.4 1.3h1c1.1.2 2.1.2 3.1.5 1.2.6 2.9.8 2.5 2.8-1.1 5.3-2.4 10.7-3.5 16 .6-7-.8-13.3-8.4-16.6z"/>
  <path fill="#B06D34" d="M130.7 198.5c-5.9 0-11.7-.1-17.6-.1-.9-.4-1.5-1-.5-1.9 5.1-4.8 10.9-4.2 17.7-2.3-1.3 1.1-1.8 1.5-2.4 1.8 1 1 1.9 1.7 2.8 2.5z"/>
  <path fill="#E2934F" d="M119.9 25.2c0 3.7 0 7.4-5.7 7.1h-.5c-1.8-.9-4.4.7-5.6-1.6l-1.6-3.4c2-1.6 4.1-3.3 6.1-4.9 1.8 2 4.9 1.8 7.3 2.8z"/>
  <path fill="#6D381A" d="M109.2 13.1c-6.5 1.8-13.1 2.7-19.9 1.5-4.5-1.7-4.6 4.2-8.2 4.1 1.8-3.9 7.4-5.6 7.4-10.5.4.1.5.4.3.7.8 1 1.5 1.9 2.1 2.9.5.4 1.1.4 1.6 0h.8c1.3.7 2.2.1 3.3-.6 1-.7 1.8-1.8 3.4-1.6.3-.1.5-.1.8-.1.2.1.5.3.6.6 1.5 1.6 1.4 0 1.8-.7V9c1.2.2 2.5.4 3.7.6.3 0 .5.1.8.3 1.2.6 1.5-.3 1.7-1.1v-.5c1.5 0 3.1.1 4.7.1.3 1.8-.8 2.5-2.5 2.8-.8.6-1.6 1.3-2.4 1.9z"/>
  <path fill="#CB9161" d="M93.8 79.3c0-.3.2-.6.4-.8l-.1.1c.9-.5 1.7-1.2 2.3-2.1 1.5-2 3.8-3.7 4.3-6.1 1.2-6.1 2.2-12.4 1.1-18.7-.1-.3-.1-.6 0-1 .2-.8.2-1.6.2-2.4.1-.4.2-.6.5-.8.4-.1.7-.1.9.1 3.3 9.5 2.9 18.9-1.5 28.1-1.5.6-1.7 2.2-2.7 3.1-1.4 1.3-2.5 3.5-5.1 1.8V80c-.3-.1-.4-.4-.3-.7z"/>
  <path fill="#9E6034" d="M143.6 191.9c1.8 1 3.1 2.7 5.2 3.2.8.7 1.6 1.3 2.4 2.1.9.4 1.8.7 3.4 1.3h-8.8c-.5-.4-1-.8-1.5-1.3-4.2-.4-9 .4-10.6-4.9 3.2-2.3 6.5-2.5 9.9-.4z"/>
  <path fill="#E6C07E" d="M138.2 117.8c-4.7 1.3-9.3-.1-13.9-1.4-.3 3 5.4 2.9 2.3 5.7-.8.7-3.7-.4-5.4-.7.1-2 .2-3.4.3-4.8.3-.6 1-.4 1.5-.7.8-.4 1.6-.9 2.5-1.3 4.2 1 8.5 2.1 12.7 3.2z"/>
  <path fill="#784621" d="M87.2 100.3c.5.6.7 1.3 1.1 1.9.1.5 0 .9-.3 1.3-.2.2-.5.4-.7.6-.4.2-.7.6-1.1.9-.3.2-.6.4-1 .4-1.1-.6-1.2-1.6-1.4-2.7 0-1.3.2-2.5.1-3.8.1-1.1-.3-2.3-.1-3.4.1-1 .3-1.9 1.2-2.6.8-.1 1.5-.1 2.2-.1.2.1.4.1.6.2.2 0 .5-.1.5-.3.1-.2.2-.4.4-.6.8.1 1.6.4 2.4.6.7.8 1.5 1.6 2.1 2.3l-6.6.9c.1.4.1.9.1 1.3h21.2c-8.9 2.3-15.5-.9-21.4 2.4l.7.7z"/>
  <path fill="#BC8A62" d="M147.4 134.5c-.7-.4-1.7.3-2.1-.6.5-.6 1.1-1.3 1.6-2 1.1-2.7 3-5.3 1.5-8.4-.2-.3-.5-.5-.8-.7-.2-.2-.5-.5-.8-.7-1.1-.7-2.7-.6-3.8-1.4-.5 0-1-.1-1.5-.1h-.1c-.1-.6-.1-1.3-.1-2 12.5 2.6 10 11 8.3 18.9-1.3-.7-2.5-1.4-3.8-2.1-.1-1.1.6-1 1.6-.9z"/>
  <path fill="#CB844D" d="M178.8 40.2c1 .4 1.9.6 2.8 1-2 4.8-4 9.6-6 14.3-.6-1.3-1.1-2.5-1.7-3.7 1.6-3.8 3.3-7.7 4.9-11.6z"/>
  <path fill="#D28545" d="M15.1 97c.1.2.1.4.2.6 2.5 2 5.4 3 8.2 4.5.5.3 1.1.6 1.6.9l1.1.6c.4.2.8.4 1 .8 2.2.3 2.9 1.4 2 4.5-5.5-3-10.6-5.9-15.7-8.8.4-1 1-2.1 1.6-3.1z"/>
  <path fill="#A16239" d="M122.9 21c-1 1.5-2.1 2.9-3.1 4.3-3.4 1.2-6 .3-7.4-2.9 3.5-.4 7-.9 10.5-1.4z"/>
  <path fill="#9A7A6B" d="M52.4 193.6c1.1 6 8.5-.6 10.5 5.1-5.1-1.2-11.7 3-13.8-4.9.7.5 1.4 1.1 2 1.6.5-.7.9-1.3 1.3-1.8z"/>
  <path fill="#C99E7B" d="M90.3 164.2c.5-.9 1-1.9 1.5-2.8 2.8-.5 4.9.1 6.1 2.6l.7 4.5c-2.7-1.4-5.5-2.8-8.3-4.3z"/>
  <path fill="#C68B55" d="M164.2 86.4c-2 .4-1.7-1.6-2.6-2.3-.2-.4-.2-.8-.5-1.3 1.2-2.8 2.5-5.7 3.7-8.6-.2 2.3-1.5 4.9 1.6 6.5-.7 1.9-1.5 3.8-2.2 5.7z"/>
  <path fill="#D78C44" d="M31.9 120.6c-1.3-2.7-2.7-5.4-4.1-8.2 4.4.1 3.5 4.2 5.5 6.1-.4.7-.9 1.5-1.4 2.1z"/>
  <path fill="#CE703D" d="M121.9 11c.8-1.8 2.5-2.3 4.5-2.3.7.1 1.4.4 2 .7.7.6 1.5 1.2 2.1 2 0 .4.1.8.1 1.1-2.9 2.3-5.4-.2-8.2-.6-.3-.2-.5-.5-.5-.9z"/>
  <path fill="#82502A" d="M101 77.4c-.9 1-.2 2.2-.5 3.2.4.1.8.2 1.2.4-2.5-.1-5.1 2.9-7.6-.2 2.5-1.7 5.1-3.3 7.7-4.9-.2.4-.5.9-.8 1.5z"/>
  <path fill="#9E5629" d="M117 12.2c-.2-3.1.2-3.5 3.8-4.2 2 .1 4.1.3 6.1.4.1 0 .2.1.2.3.1.2 0 .4-.3.5l-4.6 2.1c-1.6.8-3.4.7-5.2.9z"/>
  <path fill="#783716" d="M122.3 11.9c-3.3 0-6.7.1-10 .1 1.9-2.3 4.1-4.3 7.5-4.4.3.1.5.2.8.4-1.2 1-2.5 2-4.5 3.6 2.8-.1 4.4-.2 6.1-.3.1.1.2.4.1.6z"/>
  <path fill="#BD926F" d="M54.1 48c-1.8 1.7-1.9 4.7-5.1 5.3-.5-.3-1-.5-1.5-.8 2.2-3 4.5-6.1 6.8-9.2-.2 1.1-.4 2.2-.5 3.3.5.4.5.9.3 1.4z"/>
  <path fill="#CA9062" d="M111.4 11.1c.8-.9 1.6-1.8 2.5-2.8.2-.1.4-.1.6-.1.4-.1.6-.3.8-.6-.2-.4-.4-.9-.6-1.3 1.7.4 3.4.8 5.1 1.3-2.5 1.5-5 2.9-7.5 4.4-.2-.3-.5-.6-.9-.9z"/>
  <path fill="#372020" d="M107.1 198.4l1.5-3.3c-.1 0-.2-.1-.2-.3s.1-.3.3-.4v.1c1.3.6 2.5 1.3 3.8 2 .2.6.4 1.3.5 1.9h-5.9z"/>
  <path fill="#885C40" d="M26.7 80.4c.2-.1.3-.3.3-.5l.6-.9c.1-.1.2-.2.2-.4l6.3-7.5c-2.9 3.9-5.7 7.8-8.7 11.7.4-.8.8-1.5 1.3-2.4z"/>
  <path fill="#AD6222" d="M164 87.1c-.2.3-.2.5-.4.7-.3.5-.5.9-.8 1.4-2.6-.1-2.2-2-2.4-3.5v-.1c.5-.6.3-1.8 1.5-2.1.8 1 1.6 1.9 2.4 2.9-.2.2-.3.5-.3.7z"/>
  <path fill="#CAAC8B" d="M128.7 83.6v1.3c-2.7.2-5.3.4-8 .6.2-.6.4-1.3.6-1.9h7.4z"/>
  <path fill="#A08078" d="M110.1 33.4c1.4-.1 2.1.7 2.4 2 0 .2.1.4.1.6-.5 2.8-1.1 5.6-1.6 8.3-.7-3 1.9-6.6-1.8-9.1-.3-.9-.3-1.8.9-1.8z"/>
  <path fill="#AD8A69" d="M132.3 75.7c-.5.1-1 .1-1.5.1-.4.4-.9.4-1.5.3-.7-.6-1.6-.6-2.4-1-1.4-.2-2.7-.5-4-.7 3.4-1.5 6.6-1.5 9.4 1.3zm1 8.3c-1.8-3-2.2-3.8-2.7-4.6l1.6-3.6c.2 1.9.5 3.8 1.1 8.2z"/>
  <path fill="#AB866D" d="M101.6 40.8c1.1 1.4 2.1 2.8 3.2 4.2-.5.9-1 1.8-1.5 2.8h-.6c-2.1-2.8-2.5-5.3-1.1-7z"/>
  <path fill="#BD926F" d="M47 53.4c.1 2.2-.8 3.6-3.2 4.2 1.1-1.5 2.2-2.8 3.2-4.2z"/>
  <path fill="#C68B55" d="M166.4 80.6c.5-1.6 1.1-3.2 1.6-4.8-.5 1.7-1 3.3-1.6 4.8z"/>
  <path fill="#C3A795" d="M130.5 9.9c-.7.5-1.4.6-2-.1-.5-.2-1.1-.5-1.7-.7v-.8c1.3.6 2.5 1.1 3.7 1.6z"/>
  <path fill="#653424" d="M130.6 11.3c-.6-.6-3.1 1.1-2.1-1.5.7 0 1.3.1 2 .1 0 .5.1.9.1 1.4z"/>
  <path fill="#CC8D4F" d="M100.5 80.6c.2-1.1.4-2.1.5-3.2-.1 1-.4 2.1-.5 3.2z"/>
  <path fill="#E2934F" d="M112.6 34.8c-.6-1.2-.8-2.3 1.1-2.5l.4.2c-.2.8-.4 1.8-.6 2.6-.4 0-.7 0-.9-.3z"/>
  <path fill="#BE7B48" d="M147.4 96.7c-1.3.1-2.5.1-3.8.1 1.2 0 2.5 0 3.8-.1zm13.7-13.9l.7.6-1.5 2.1c.3-.8.5-1.8.8-2.7z"/>
  <path fill="#D69148" d="M125.4 114.3c.9-.2 1.9-.4 2.8-.6-.9.2-1.8.4-2.8.6z"/>
  <path fill="#170C09" d="M89.5 6.9L88.9 9c-.1-.2-.2-.5-.3-.7.3-.4.6-.9.9-1.4z"/>
  <path fill="#8B5A3F" d="M79.7 160.1c-.2-.5-.5-1.1-.8-1.6.5.5 1.1.9 1.7 1.4-.3.2-.6.2-.9.2z"/>
  <path fill="#C68B55" d="M168 75.8c.2-.4.4-.9.6-1.4-.2.5-.4.9-.6 1.4z"/>
  <path fill="#CB844D" d="M173.9 51.9c-.3.5-.5.9-.8 1.4.3-.5.6-.9.8-1.4z"/>
  <path fill="#D78C44" d="M29.5 127.3c-.1.3-.2.6-.3.8.1-.2.2-.5.3-.8z"/>
  <path fill="#9A7A6B" d="M51.6 192.3l-.8-.8c.2.3.5.5.8.8zM52.3 193.1c-.2-.3-.5-.6-.8-.8.3.2.6.5.8.8zm-4 0c.3.3.6.5.9.8-.3-.3-.6-.6-.9-.8z"/>
  <path fill="#CE813B" d="M26.9 80c.1.3-.1.4-.3.5 0-.2.1-.4.3-.5zm.6-1.1l.2-.1c.1.2 0 .3-.2.4v-.3z"/>
  <path fill="#AD8A69" d="M129.9 78.7l.8.7-.8-.7zm-1.2-.7c.4.2.8.5 1.2.7-.4-.3-.8-.5-1.2-.7z"/>
  <path fill="#BD926F" d="M47.6 52.5c-.2.3-.4.6-.6.8.3-.2.5-.5.6-.8z"/>
  <path fill="#A08078" d="M112.2 35.5c.1-.5.5-.7 1-.6.2.1.2.1.3.2-.3.3-.7.6-1 .8-.1-.1-.2-.3-.3-.4z"/>
  <path fill="#9E5629" d="M127 19.5c.2-.1.4-.2.5-.4-.1.1-.3.3-.5.4z"/>
  <path fill="#BE7B48" d="M148.3 96.7h-1 1z"/>
  <path fill="#C68B55" d="M163.5 87.8c.1-.3.2-.5.4-.7-.1.2-.2.5-.4.7z"/>
  <path fill="#C77F40" d="M88.8 114.3c-1.3-1.4-2.5-2.8-3.8-4.2-1.1-1.6.3-2.5 1.1-3.6 2.4-2 4.9-2.3 7.6-.5.4.5.2 1.1.4 1.7.2-.3.2-.6.3-.9.2-.3.4-.5.6-.7 4.7-2.3 9.5-.6 14.2 0 .3.2.5.5.7.8-.1 2.4-.8 4.5-2.8 6-5.4-3.2-10.6-1.8-16 0-.9.4-.9 2-2.3 1.4z"/>
  <path fill="#A4602C" d="M101.8 141.7c.2-.8.5-1.7.8-2.5-11.9-.7-23.6-1.3-35.5-2 .3-1.6.5-2.8.4-1.9 12.5 1.1 24.6 2.1 37 3.2-.7-1.5-1.1-2.5-2.8-6.6 4.2 6.1 6.6 9.5 8.8 12.7-3.7.7-2.1-8.3-7.9-2.8-.3.3-.6.4-.8-.1z"/>
  <path fill="#915626" d="M119.2 106.4c0 .7-.8.7-1.2 1.1 1.8-.1 3.6.3 5.4.5-1.1-.4-2.1-.7-1.7-2.3 2.4-1 5-.9 7.6-.4.9.9 2.2 1.4 2.8 2.2.3 1.3-1.2 1.3-1.4 2.1l-3.9 1.2c-.5-.4-1-.4-1.5 0-1.5 0-2.9.1-4.3-.1-4.7-.8-10.1-4.6-12.5 2.9-.4.4-.8.6-1.5 1.1 0-.8-.1-1.3-.1-1.8l2.4-5.4c1.4-3.6 5.7-4.2 9.9-1.1z"/>
  <path fill="#A36536" d="M147 128.9c-4.8-.4-9.3-.8-15.6-1.4 3.9 1 6 1.5 8 2-6.1-.9-11.1-1.1-11 6.6-2.8-1.7-1.5-3.6-.2-5.4 2.4-3.4 5.4-4.8 10-4 3.7.6 9.1 4.2 10-3.2 2.8 3.3 1.1 5.9-1.5 8.4 0-1 .1-2.1.3-3z"/>
  <path fill="#A86932" d="M130.9 148.6c-3-1.8-1.3-4.9-2.4-7.2-.1-.1.1-.1.2-.1.5 0-.3-.3-.3.2.4 3 1.6 6 1.1 9.1-.3.2-.6.4-1 .4-2-.9-1.8-2.9-2.5-4.5-1.1 1.2-1.8.8-2.6-.2-.3-.4-.6-.7-1-1.1-.5-2.2-1-4.3-1.5-6.5.2 0 .5-.1.7-.1.5 1.7 1.2 3.4 1.7 5.1.3 0 .5-.1.8-.1-.4-1.8-.7-3.5-1.1-5.3l.1-.1 5.3-.6c1.1 3.7 2.1 7.5 3.1 11.2-.3 0-.5 0-.6-.2z"/>
  <path fill="#AE6D2F" d="M59.6 128.5c-1.5-2-.3-3.8.5-5.6.9-2 1.7-3.9-.2-5.8-.8-.8-1-1.9.1-2.7.3-.1.6-.1.8-.1.3 0 .5.1.8.1 7.2 3.3 13.4 1.3 19.1-3.2.7-.6 1.3-1.1 2.2-1.3.7 0 1.1.3 1.3.8-5.1 6.1-11.4 9.5-19.8 7.5-1.3 3.7-2.5 6.9-3.6 10-.4.4-.7.4-1.2.3z"/>
  <path fill="#8D5A33" d="M113.9 139l-1.5-3.3c6.7 2.3-.1 6.9 1.4 10.5l-4.4-5.3c.3-2.9 3.2-.9 4.5-1.9z"/>
  <path fill="#793F25" d="M58.4 134.4c.1-2-2-3.7.3-5.4 2.4.7 2.7 3.7 5.3 4l.4.4c-1 1.3-2 2.5-3 3.7-1.1.8-2.5.9-3.8.5-2.1.2-4.1.4-6.1.6 1.5-2.5 4-3.6 6.9-3.8z"/>
  <path fill="#80563F" d="M101.6 142.3c.5.3.5.6.2 1.1-.4.2-.8.4-1.1.6-9.6-.8-19.1-1.5-28.6-2.3 9.8.1 19.7.3 29.5.6z"/>
  <path fill="#793D29" d="M73.3 129.8c-.1-.6.2-.9.9-.7-.5 6.9-7.5.8-9.8 4.2l.1.1c-.5-1.1-1.3-1.9-2.5-2.5-1.1-.5-2.4-.9-2.1-2.5.3 0 .5-.1.8-.1 4.2.9 7.8 5.3 12.6 1.5z"/>
  <path fill="#A86D34" d="M120.6 129.8c-1.6 3-.7 5 3.1 5.6-.2 1.1-.5 1.9-.7 2.8l-.1.1c-4.1-1.8-6.2-4.4-3.1-8.5.3-.4.6-.4.8 0z"/>
  <path fill="#774230" d="M108.6 145.2c0 .4.1.8.1 1.2-1 .3-2 .5-3.4.8 2.8.7 1.9 2.3 1.6 3.7-.2.7-.2 1.6-1.2 1.7-3.3-1-1.5-3.4-1.8-5.2.3-.8.5-1.5.8-2.2h3.9z"/>
  <path fill="#80544E" d="M108.5 161.8c-1.2-1.9-.6-3.5 1.4-4.7 1.8 0 1.5.9 1 2l2.4 1.8c.3.1.5.1.8-.1 1.1 2.6-2.1.7-2.3 1.8-1.7 1.4-2.7.3-3.3-.8z"/>
  <path fill="#783B24" d="M124 146.1c-.2.6-.1 1.1.1 1.6.3 3.3.5 6.5-1.8 9.3-.5-.5-1-.9-1.5-1.4l1.6-10.4c.5 0 .8-.1 1.3-.1.4.4.5.8.3 1z"/>
  <path fill="#815435" d="M109.9 157.3c-2.8-.1-3.4-1.5-2.5-3.7 1.1-1.1 2.3.6 3.4.1v5.4l-.9-1.8z"/>
  <path fill="#A76424" d="M106.2 125.3H117v.4c-3.6.2-7.3.4-10.9.6.1-.4.1-.7.1-1z"/>
  <path fill="#A86A31" d="M120.3 146.9c-.5.1-.9.2-1.4.3-.6-3.5-1.3-6.9-2-10.4 1.2 3.4 2.4 6.8 3.4 10.1z"/>
  <path fill="#C38C59" d="M62.2 140.3c-1.2.8-2.5.4-3.4.3-1.6-.9-.8-2.1-1.1-3 1.3-.1 2.5-.4 3.8-.5.2 1 .5 2.1.7 3.2z"/>
  <path fill="#7A462D" d="M64.5 140.9l-3.2.2c-1.8.1-3.7.1-5.7.3.7-1.3 1.4-2.6 2-3.9.8 1.9 1.9 3.5 4.6 2.7.8.3 1.5.5 2.3.7z"/>
  <path fill="#9C5A24" d="M103.4 119.8c3.8-.8 7.7-1.6 11.9-2.4-3.9 2.5-7.8 3-11.9 2.4z"/>
  <path fill="#9E602E" d="M74 125.4c-1-1.1-2.4-.9-3.7-.9l-.5-.2c1.7-4.6 4.5-1.1 7.9-1.3-1.7 1.4-2.5 2-3.4 2.8-.1-.2-.2-.4-.3-.4z"/>
  <path fill="#95592A" d="M115.4 114.4c2.5.5 5 .9 7.5 1.4-.5.2-1 .5-1.5.7-1.9-.7-3.9-1.4-6-2.1z"/>
  <path fill="#7F4723" d="M127.1 125.9c-.8 2.8-1.7 5.4-2.5 7.9-.1-2.8-1.4-5.9 2.5-7.9z"/>
  <path fill="#491C16" d="M107.5 137.2c2.7.7 4.6 1.2 6.4 1.7-1.5.7-3 1.3-4.4 2-.6-1-1.2-2-2-3.7z"/>
  <path fill="#A8682D" d="M141.3 120.6c-3.9 1-7.9 1.9-11.9 2.8.2-.6.5-.8 1.1-.8 3.6-.6 7.2-1.3 10.8-2 0 0 .1.1 0 0z"/>
  <path fill="#A36536" d="M128.4 136.2c.9-1.4 1.8-2.8 2.8-4.1l1.1.6-3.9 4.9v-1.4z"/>
  <path fill="#9E602E" d="M69.8 125.1c.1-.1.2-.2.4-.3 1.4.1 2.8.5 4.2.8 0 1.1-.1 2.3-.1 3.5-.3.2-.6.5-.9.7-1.3-1.5-2.5-3.2-3.6-4.7z"/>
  <path fill="#A35826" d="M124.5 124.5c.3.2.5.4.8.6-1.5 1.6-3 3.2-4.5 4.7h-.8l4.5-5.3z"/>
  <path fill="#48201E" d="M106.3 152.3c.2-.4.5-.9.7-1.3l3.9 2.8h-3.1c-1.2.1-1.6-.4-1.5-1.5z"/>
  <path fill="#A35A27" d="M112.9 132.6c.3-.2.7-.4 1-.6 1 1.6 2.1 3.3 3.1 4.9-1.4-1.5-2.8-2.9-4.1-4.3z"/>
  <path fill="#9D5D28" d="M88.3 116c.2-.6.3-1.1.5-1.6.8-.4 1.5-.9 2.3-1.3l-2.8 2.9z"/>
  <path fill="#E6C07E" d="M128.1 122.2c-.1-.1.5-.6.8-1 .5.4 1.2.9 1.7 1.4-.4.3-.7.6-1.1.8-.5-.4-1.1-.7-1.4-1.2z"/>
  <path fill="#602B02" d="M84.5 107.1c.3-.3.6-.4.9-.6.4-.1.8-.1 1.1.1.2.2.3.5.2.8-.6.9-1.1 1.8-1.7 2.8-.2.2-.5.4-.8.6l-.9-.6c-.4-1.4.3-2.3 1.2-3.1z"/>
  <path fill="#B15A1E" d="M95.7 132.1c.8 2.4.3 1.1-.1-.1.8 1 1.7 2.1.1.1z"/>
  <path fill="#A36536" d="M142.8 120.7c1.2.5 2.5.9 3.8 1.4-1.2-.4-2.5-.9-3.8-1.4z"/>
  <path fill="#A76424" d="M101.6 125.9c.8.2 1.7.4 6.4 1.4-4.7-.4-5.7-.5-6.5-.6 0-.3 0-.6.1-.8z"/>
  <path fill="#572B19" d="M117.6 159.9c.2.5.4.9.5 1.4-.7 0-1.4.1-2.1.1.6-.6 1.2-1 1.6-1.5z"/>
  <path fill="#915626" d="M110.8 114.3c.1-.4.1-.8.1-1.2.5.4.9.8 1.4 1.2h-1.5z"/>
  <path fill="#854729" d="M101.6 142.3c0-.2.1-.4.2-.4.2-.1.5-.1.8-.1.3.7.5 1.4.8 2.1l-1.5-.6-.3-1z"/>
  <path fill="#774230" d="M104.8 145.2l-1.5-1.4 1.5 1.4z"/>
  <path fill="#5C2322" d="M116.2 158.6c.1-.1.2-.4.2-.5.5.1.8.4 1.2.5 0 .3.1.5.1.7-.5-.2-1-.4-1.5-.7z"/>
  <path fill="#9E602E" d="M78.7 122.5c.1-.1 0-.1.1-.2s.2-.1.3-.1c0 .1-.1.2-.1.4-.2 0-.3-.1-.3-.1z"/>
  <path fill="#BB7637" d="M114 160.8c-.3 0-.5.1-.8.1.2-.1.5-.1.8-.1z"/>
  <path fill="#B15A1E" d="M95.5 131.7c.1.1.1.2.2.3-.1-.1-.2-.3-.2-.3z"/>
  <path fill="#9E602E" d="M70 124.5c.2.1.1.2-.1.4l-.2.1c-.1-.1-.2-.2-.2-.3.1-.1.2-.4.3-.5 0 0 .1 0 .2.3z"/>
  <path fill="#A36536" d="M147.4 122.8l.8.7-.8-.7zm-.8-.7l.8.7-.8-.7z"/>
  <path fill="#D69753" d="M123.6 145.9c.1-.2.1-.4.1-.6.5.4 1 .8 1.5 1.3.2-1.6.3-3 .7-6.3 1.2 4.8 1.9 7.7 2.6 10.6.3.1.7.4 1 .6.3.3.5.6.6 1 1 3.7 1.9 7.4 2.7 11.2.3 1.7.2 3.2-1.8 4.2-2-.9-2.6-2.8-3.4-4.5v.1c-.4-.9.2-2-.8-2.8-1.5-1.2-3.1-2.3-4.6-3.5l.9-10.5c.2-.2.4-.5.5-.8z"/>
  <path fill="#844829" d="M133.3 161.9c.4-2.8-1.1-5.7-2-8.8-.4-1.4-1.3-2.9.1-4.3 3.4 2 .7 7.1 5.4 8.4.5 3 1.1 6 1.6 9 .2.8.4 1.5.5 2.3-.3.1-.5.1-.8.2-.8-2-1.5-4-2.5-6.7-1.3 3.1-2.3 5.7-3.8 9.4-1.7-3.2-2.9-5.5-4.1-7.9 1.1 1.2 2.1 2.5 5 5.8-1.5-7.4-2.5-12.6-3.4-17.7 4.2 2.7 3.1 7 4 10.3z"/>
  <path fill="#7F4F3F" d="M144 173.4h-.5c-.3-.4-.5-.8-.8-1.2 1 .1 2.5-2.1 3 .5-.4.4-1 .7-1.7.7z"/>
  <path fill="#572A27" d="M126.8 160.7c2.6.4.9 1.8.8 2.8-.1-1-3.5-1.1-.8-2.8z"/>
  <path fill="#7F4F3F" d="M142.1 174.8c.5-.5 1-.9 1.5-1.3h.1c.8-.4 1.5-.7 1.5.6l-.8.8h-2.3v-.1z"/>
  <path fill="#A86934" d="M145.7 172.7c-.2.4-.4.8-.6 1.3-.5-.1-1-.4-1.5-.6.7-.3 1.4-.5 2.1-.7z"/>
  <path fill="#D7853C" d="M72.9 38.7c1.4 1.4 1.6 3 .4 4.5-1.8 2.5-2.8 5.4-4.4 8.1-1.5 3.6-5.4 5.7-7.6 9.8 2.1-3.5 3.8-6.4 8.5-4.5-1.3-2.3-.8-4.3-.1-6.3 1.2-4 4-7.3 6.1-10.9 2.6-4.3 3.4-4.4 7.7-1.4.9.9.9 2 .8 3.2-.2 2.3-.6 4.7-1 7-.2.6-.5 1.1-.4 1.8.2.8.3 1.6 0 2.3-.6 1.1-1.7 2-1.5 3.4.3 4-1.1 7.7-2.6 11.3-1.4 4-1.2 8.6-5.4 11.4-.1.4-.2.6-.2.9-.8 2-2.1 3.8-3.1 5.7-.7 1-2 1.5-2.2 2.8-.2.2-.4.5-.6.6.5 1.9 1 3.7 1.5 5.6-1.1 1.3-2.6 1.1-4.1.7-1.6-.8-2-2.3-2.5-3.7-.2-.6-.2-1.2-.2-2 .1-.7 0-1.3 0-2 0-.1-.1-.2-.1-.3-.2.1-.2.2-.2.5-.2 2.5-.2 4.9.1 7.4-.3 1-.5 2-2.4 2.4-3.4-4.2-8.1-7.7-11.7-12.1-.7-.5-.6-1.4-1.3-1.9v.6c.6 4-2.1 8.1.9 12.1 1.2 1.6-1.4 3.5-3.7 2.6-1.1-.4-2-1-2.6-1.9-.7-1.1-.8-2.3-.6-3.4.8-6.6 2-13.1 3.4-19.6.3-1.4.7-3.1 3.1-3.3.8 1.7 1.3 3.5 2.4 5.1-2.3-4.1-3.6-8.4-2.5-13.1 0-.4.1-.6.2-1 .3-.6.7-1.1.6-1.8 0-.6.2-1.3.5-1.9.2-.5.4-.9.6-1.4 1-2.5 2.5-4.9 4.4-7 1.1.2 1.3.8 1.4 1.6-.2 2.6-1.6 4.9-2.3 7.4-2.1 7.5.8 14.1 8.1 18.2.7-2 .8-2.1-1-4.2-2.5-2.9-4.7-6-4.3-9.8.8-8.3 3.6-16 10.3-22 .9-.9 1.9-2 3.5-1.7 1.4.2 2.9-2.2 4.1.2z"/>
  <path fill="#CE8442" d="M67.5 87.7c.8-1 1.7-1.8 2.1-3 1-2.1 2.1-4.2 3.1-6.3 1.8-4 3.6-8 5.4-11.9 1-.5 1.2-2.3 3.2-1.3.2 1.8-.5 3.7-.9 5.6.6-2.4 1.5-4.9.9-7.4-.6-2.8-.1-4.7 2.8-5.1-1.5.7-2.5.1-2.4-2 .1-1.6 1.1-2.9.9-4.5-.2-.7-.5-1.3-.7-2 .6-.5 1.4-.8 1.9-1.4.7-1.6.8-3.3 1.4-4.9.9-2.6 2.3-3.3 5.1-2.1.7.3 1.4.6 2 1 .8.6 1.4 1.2 1.2 2.3-.2.2-.3.4-.2.4.1-.1.2-.4.5-.4.5-.1 1.1-.1 1.5.1.8.6 1.2 1.3 1.3 2.3 0 .6 0 1.1-.2 1.6-.5 1.3-.5 2.8-.3 4.2 0 2.4.2 4.9 1.1 7.2.2.5.3 1 .3 1.6.1 1.6-.5 3-1.2 4.3-2.3 4-5.7 7-9.7 9.5-.3.2-.7.4-1 .5-.6.3-1.3.6-1.9.8-1.1.6-2.5.6-3.5 1.1-2.1 1-4.3 2.1-3.4 4.9-1.5 4-5.7 4.4-9.1 5.7v-.8h-.2z"/>
  <path fill="#33170C" d="M85.6 74.9c2.6-.7 4.6-2.1 6.5-3.8 2.1-1.8 3.7-4.1 6.4-5.2.3.1.5.4.8.6l.5.8c.1.3.2.6.2.9 0 .5-.1.9-.5 1.2-6.4 6.4-12.5 13-22.9 13.4-.7-.5-2.1-1.4-2.1-1.5 2.1-3 4.7-5.4 9-5.4.4-.4 1.1-.3 1.5-.6.2-.2.4-.3.6-.4z"/>
  <path fill="#6C3817" d="M96.5 61.7c1.5-1.1 2.1-2.8 3.5-3.9 2.6-1.3.5-3 .4-4.5.4-1.1-.2-2.9 2.1-2.8.8 9.3 2.8 18.7-6.2 25.9 1-2.3 2.1-4.7 3.1-7.1V68c.5-1 .7-2 1.1-3-.5.5-.3 1.9-1.8 1.6-3.8 3.3-7.5 6.4-11.4 9.8-.5-.4-1.1-.9-1.7-1.4 4.8-3.7 9.7-7.2 10.9-13.3z"/>
  <path fill="#9E5D2C" d="M69 94c5.4-.4 10.7-.8 16.1-1.3-.1.9-.1 1.8-.2 2.8-2.4.1-4.7.1-7 .3-1.2.1-2.3.6-3.5.7-2.8.4-5.7.2-8.7.5-1.8.1-3.9.2-4.4-2.2V81.4c.9 3.4 1.2 4.5 1.5 5.6.5.5.5.9.1 1.4 0 .7.1 1.5.1 2.2.5 1.1-.1 2.6 1.4 3.5 1.5 0 3.1-.1 4.6-.1z"/>
  <path fill="#82502A" d="M93.4 80.1c-.7.9-1.5 1.8-2.1 2.7-.3-.3-.7-.6-1-.8.8-.8 1.6-1.7 2.4-2.5.3.1.7.1.7.6z"/>
  <path fill="#663A20" d="M131.3 78.3c-1.2-1.3-1.8-1.8-2.3-2.3.6-.1 1.1-.2 1.8-.3 0 .6.2 1.2.5 2.6z"/>
  <path fill="#6C3817" d="M96.4 76.5l-2.3 2.1 2.3-2.1z"/>
  <path fill="#9E5D2C" d="M89.5 93.4c-.8.4-1.6.6-2.2-.1v-.6l1.5-.6c.3.3.5.8.7 1.3z"/>
  <path fill="#170B0B" d="M126.8 75.1c.7.4 1.5.6 2.1.9-.6-.3-1.3-.6-2.1-.9z"/>
  <path fill="#6C3817" d="M93.3 79.1c.4.3.5.6.2.9v.1l-.8-.7c.1-.1.4-.2.6-.3z"/>
  <path fill="#82502A" d="M93.4 79.3c.2-.2.5-.2.8 0v.8c-.2.1-.5.1-.7.1-.1-.4-.1-.7-.1-.9z"/>
  <path fill="#6C3817" d="M94.1 78.6c-.1.2-.1.5-.1.7h-.8c.4-.3.7-.5.9-.7z"/>
  <path fill="#9B5E2C" d="M134.1 133c.2 0 .3-.1.5-.1 1.3 5.1 2.5 10.1 4.1 16 1.5-1.7 2.1-2.3 2.6-2.9h.1c-.1 1.1-.2 2.3-.3 3.5 1.4.6 2.8 1.2 6.7 3-4.4-1-6.1-1.4-7.5-1.7-.7 1.1-1.4 2-2 3-1.4-7-2.8-13.9-4.2-20.8z"/>
  <path fill="#925629" d="M138.3 153.8c.5 1.4.9 2.8 1.4 4.2-.2.3-.4.5-.7.6-.8-.5-1.5-.9-2.3-1.4-1-4.5-2.1-8.9-3.1-13.4 1.6 3.3 3.1 6.7 4.7 10z"/>
  <path fill="#A96928" d="M140.8 142.8c-.2-.1-.4-.1-.6-.1-1.2-3.5-2.4-7.1-3.5-10.7 1.4 3.4 2.7 6.9 4 10.2 0 .2.1.3.1.6z"/>
  <path fill="#9B5E2C" d="M142.1 142.5c1.3.6 2.6 1.3 4 1.9-1.5.5-3.1 1.1-4.7 1.6h-.1c-.3-1.1-.7-2.2-1-3.3.2-.1.3-.3.4-.4.5 0 .9.1 1.4.2z"/>
  <path fill="#733824" d="M139.9 132.6c4 1 4.6 1.2 5.2 1.3 1 .1 2 .2 3.1.4-.9.5-1.7.8-2.4 1.1-1-.5-2-.9-5.9-2.8z"/>
  <path fill="#8B4F2E" d="M141.2 159.5c1.2.6 2.3 1.3 3.4 2-.2.3-.4.6-.6.8l-3.4-2.3c.1-.3.2-.5.6-.5z"/>
  <path fill="#925629" d="M133.7 143.9c-.4-2.1-.2-1 0 0-.5-.9-1-1.9 0 0z"/>
  <path fill="#9B5E2C" d="M142 142.5c1.2-1.1.6-.6 0 0 .5-.7.8-1.4 0 0z"/>
  <path fill="#69301D" d="M138.9 158.6l.7-.6 1.5 1.5-.6.6c-.5-.6-1-1-1.6-1.5z"/>
  <path fill="#A96928" d="M136.6 131.7c.1.1.1.2.2.3-.1-.1-.2-.3-.2-.3z"/>
  <path fill="#884B1E" d="M41.6 96.1c.2 1.5 1 3.1-1.5 3.7-1.3-1.1-1.9-2.4-1.8-4 1.8-7.4 1.9-15.2 5-22.5 0-.1.2-.2.2-.2l-.1-.1c-1.1 4.2-3.6 7.9-5.7 11.7-.5.9-1.2 1.8-2.6 1.8l-.4-.4L47 60.3c.5.1.8.4.8.8-.5 6.7.1 13.1 4.7 18.8-2.1-3-4.1-6.1-7-10.1-1.4 9.4-2.7 17.8-3.9 26.3z"/>
  <path fill="#7E4217" d="M27.3 98.9c2.5-4.2 4.8-8.5 7.3-12.9.2.1.3.2.5.3 1 2-1.1 3.5-1.2 5.4 1.1 1.5 3.8 1.4 4.2 3.6-.5 1-1.5 1.6-2.5 1.8-.8.1-1.5.1-2.2-.2 5.1 3 5.6 3.7 3.8 6.8-3-1.3-5.2-4-8.5-4.8h-1.4z"/>
  <path fill="#8D4C1B" d="M24.9 101.1c0 .9-.5 1.6-1.4 2-2.7-2-7.2-1.7-8.3-5.7 4.6-1.8 6.6 2.1 9.7 3.7z"/>
  <path fill="#662C0E" d="M46.9 60.4c0-.5.1-.9.2-1.3.4-.4.2-1.1.6-1.5l1.4-4.2c1.5-1.9 3.1-3.9 4.6-5.8 1.6.9 2.3 1.8.2 3-.2-.2-.2-.4-.4-.6 0-.1-.3-.1-.3 0-1.3 2.3-2.7 4.7-4.1 7-.2.3-.4.6-.5.8-.2.6-.2 1.3-.3 2-.2.4-.2 1-.5 1.4v.1l-.9-.9z"/>
  <path fill="#231313" d="M25.6 101.3c.2.1.5.2.8.4.2.1.5.1.7.1v2.8h-1.4c-.3-.2-.6-.4-1-.5-.4-.3-.8-.6-1.2-.8.5-.7.9-1.3 1.4-2 .2-.2.5-.1.7 0z"/>
  <path fill="#884B1E" d="M47.6 57.5L47 59c.3-.6.5-1 .6-1.5z"/>
  <path fill="#8D4C1B" d="M26.4 101.6c-.2-.1-.5-.2-.8-.4.3.1.5.2.8.4z"/>
  <path fill="#D78C44" d="M35.5 96.9c2.1.1 2.5 2.6 4.6 2.8l1.5-3.6c1.5.7 2.8 1.3 6.4 3-4.3-6.8-2.9-12.3-1.8-17.7.5.3.8.7.8 1.3.2.6.4 1.1.5 1.6.8 1.5.5 3.1.6 4.7v2c-.1 1.2.6 1.9 1.8 2.4 1.6.6 2.1 2 2.3 3.4.4 2.2-.9 3.8-2 5.5-1.1 1.8-1.7 3.6-1.1 6.4 1.1-2.2.1-5.2 2.9-4.9 2.8.3 3.3 2.9 3.6 5.2.1.8-.2 1.6-.5 2.4-.2.7-1 1.4-.2 2.2 1.8-.8 1.9-2.4 2.1-3.9.2-1.5 0-3 1.1-4.3 1.5-.7 2.9-.1 4.4.1 5.7 1.1 11.6-.8 15.2-4.7-1.8-1.3-3-2.9-2.6-5.2 1.1-.1 2.1-.4 3.1-.6 1.8 2 5.2 1.6 6.7 4.1 0 1.4.1 2.8.1 4.1-1 1.4.7 2.3.8 3.5v.1c-.2.2-.4.5-.6.7-.5.9-1.1 1.8-1.8 2.8-6.4 6.8-13.9 9.3-23.7 4.9 8 4.7-1.1 8.9.3 13.3.1 2.8 7.2.1 4.6 4.9-4.2 2-3.9-2.4-6.9-4.6 1.4 3 2.2 4.8 3.6 7.7-4.5-2.1-6.9.4-9.7 1.7l-.7-.6c-1.2-.3-2.5-.4-3-1.7-.4-1.1-.7-2.4 1.5-1.9l.2-.2c-.5-.1-.9-.4-1.3-.8-.2-.2-.2-.6-.2-.8.1-.9.6-1.7.8-2.5 0-.3.1-.6.2-.8.2-.4.6-.6.9-.9.2-.1.5-.4.7-.5.8-.3 1.4-.6 1.8-1.3.3-1.5 1.5-2.8 1.8-4.3.2-.2.2-.4.4-.5 0 0-.1.1-.2.3-.5 1.5-1.5 2.8-1.9 4.4-.2.2-.4.5-.6.7-.7.5-1.5.6-2.1 1.2-.4.3-.7.6-1 1-.3.6-.8 1.1-1.5 1.3-2.5 1.5-4.5 1.1-5.8-1.5-.4-.7-1.1-1.2-1.4-2-.1-.5.1-1 .4-1.4 1.4-2.3 2.9-4.5 3.6-6.9-.7 2.4-2.1 4.5-3.4 6.6-1.1.8-.6 2.8-2.5 3-4.8-1.9-3.4-5.1-1.5-7.8.6-.8 3.4-.6 2.4-2.7-.2-.5-2.4-.3-3.6-.4.1-.4.2-.8.4-1.2-.6-.5-.5-1.2-.8-2 .3.8 0 1.5-.5 2-.3 0-.7.1-1 .1-1.3-2.3-2.5-4.5-6.2-10.8 4.1 4.3 4.8 5 6.3 6.6-.5-3.4.5-5.4 4.8-7.6-3.9.9-5.5 1.3-7.6 1.8-.8-2.3-1.7-4.6-2.5-6.9 4.2-1.2 5.8 2.8 9.3 4.4-.8-5-6.1-4.5-10.6-9 4.7 1.1 6.5 1.7 8.3 2.3z"/>
  <path fill="#AD6E32" d="M47.8 129.1c.8.6-.9 1.3.3 1.9.3.3.5.6.6 1-.1.2-.1.4-.1.6-1.9 1.8.4 4-.8 5.8-2.6 1.6-4.7 1.1-6.7-1.1.8-1 1.6-1.8 4.7-5.2-4.3 3.3-5.8 4.4-7.4 5.5-1.5.6-2.3-.4-3.5-1.3 1.9-3.4 2.6-7.3 5.8-10.1 1.2 1.3 2.4 2.5 3.6 3.8 1.3-.2 2.3-.5 3.5-.9z"/>
  <path fill="#66301F" d="M33.2 138.3c1.7-3.5 3.4-7 5.2-10.5.8-.9 1.6-1.8 2.4-2.8l-.1-.1v1.3c-1.7 3.3-3.4 6.5-5.4 10 1.1.5 2.1.8 3.1 1.3-1.6.3-3.4.5-5.2.8z"/>
  <path fill="#90512F" d="M48.6 132.6c1 .6 2.1 1.3 3.2 2 0 .1.1.4.2.5-.2-.1-.4-.1-.6-.1-1-.1-2-.4-3-.5-.5.5-.8 1-1.3 1.5 1.2.6 2.5 1.1 3.8 1.7-1 .2-2 .5-3 .8-1.2-2.2-1.3-4.2.7-5.9z"/>
  <path fill="#DCD7D6" d="M65.9 146c4.1 2.6 8.1 5.2 12.2 7.8-4-2.6-8.1-5.2-12.2-7.8z"/>
  <path fill="#925B3B" d="M76.5 157.3c.5-.3 1.1-.5 1.7-.8-.2.5-.5.9-.8 1.4-.4-.2-.7-.4-.9-.6zm2.9-1.9c-.5.4-.8.8-1.3 1.1.5-.4.9-.7 1.3-1.1zM77.4 157.9c.5.2 1 .5 1.5.7-.5-.2-1-.4-1.5-.7z"/>
  <path fill="#72452D" d="M123.8 190.2c7.7 2.6 16.6-6.3 23.6 2.3.4.2.4.7.6 1.1.2.4.2.6.4.9.1.2.2.4.5.5l.1.1c-5.1-.6-9.3-5.9-15.2-2.8-3.4-.6-6.7-1.4-10-2.1z"/>
  <path fill="#9E6034" d="M148 193.6c-.2-.4-.4-.7-.6-1.1.2.4.3.7.6 1.1zm.8 1.4c-.2-.1-.3-.4-.5-.5.3.1.4.3.5.5z"/>
  <path fill="#E8934B" d="M91.1 42.5c-.2-.2-.3-.4-.5-.6l-.3-.3-.7-.4c-.3-.1-.5-.3-.8-.4-.5-.1-1-.1-1.4-.4-1.1-.9-.8-2.1-.6-3.3.7-5.4 2.4-10.5 6.5-14.6l.9-.6c1.3-1 2.8-1.8 1.1-3.6-.5-.6-1.3-1.3.4-1.6 1.8 1.5 3.4 2.9 5.2 4.3v5.7c-3.4 2.8-3.4 6.1-2.2 9.8.7 2-.4 4.1.5 6.1 0 .7-.3 1.3-.8 1.8-.2.2-.5.4-.9.5-.9.4-1.8.1-2.7 0h-1.4l-1.5-2.1c-.3-.1-.5-.3-.8-.3z"/>
  <path fill="#471B0C" d="M101.7 40.8c.3 2.3.7 4.7 1.1 6.9l-.2.6c.5.8.5 1.5.1 2.3-.6 1.1-1.1 2.3-1.7 3.4 1.1 1.6 1.1 3-.7 4.2-1 1.4-.6 4-3.7 3.5-.1-.5-.2-.9-.2-1.4 2.5-3.8.7-8.3 2.1-12.2.5-1.4-1.1-2.5-.5-3.9.3-.4 1.2-.6.7-1.4-1.8-2.1-1.2-4.2.1-6.3.9 1.5 1.9 2.9 2.9 4.3z"/>
  <path fill="#532A1A" d="M109.1 35.1c-.4-1.4-.8-2.9-1.1-4.4l5.7 1.5c-.2.8-.4 1.8-.5 2.6-.3.2-.7.4-1 .6-1.1-.1-1.7-3.5-3.1-.3z"/>
  <path fill="#894F1F" d="M133.1 105.4c-7-.4-14 .5-21-.6-1.1-.1-2.2-.4-2.4-1.8l.2-.6c10.2.2 20.3.5 30.5.7-1.2 3-4.1.5-6 1.5-.4.4-.7.8-1.3.8z"/>
  <path fill="#CB9059" d="M86.6 100.4c4.7-.6 9.5.1 14.3-.1 1.4-.1 2.8.4 3.9-.7l2.3-.4c0 .6-.1 1.2-.1 1.8.5.5.6 1.1.5 1.7-.3.6-.8 1-1.5 1.2-5.5 0-11-.6-16.5-.6-.6.1-1.1-.4-1.6-.8l-1.3-2.1z"/>
  <path fill="#A2612A" d="M86.6 100.4v-.8c6.1.1 12.1.1 18.2.1-5.9 3.1-12.1.5-18.2.7z"/>
  <path fill="#6B4421" d="M128.4 98.2l3-.6c3.1.4 6.3.8 12.4 1.5h-18.5c-1 .3-2 .5-2.9-.1 1.9-.3 4-.6 6-.8z"/>
  <path fill="#7F4616" d="M123.2 101c6.1-.6 12.2-2.1 18.1.8-6-.3-12.1-.5-18.1-.8z"/>
  <path fill="#5C2B0A" d="M110 102.4c-.9 1-1.9 1.6-3 .1v-1.6c1 .5 2.1 1.1 3 1.5z"/>
  <path fill="#804E25" d="M34.8 115.8c.1-1.5.2-2.9.2-4.3h1.1v4.2c-.3 0-.7.1-1.3.1z"/>
  <path fill="#D78C44" d="M33 127.8c.1-.1.2-.1.2-.1 0 .1-.1.1-.2.1zm.5-.2c-.1.1-.2.1-.2.1.1 0 .2-.1.2-.1z"/>
  <path fill="#CD885E" d="M97.8 9.7l.9-.4h.1c.5-.6.8-.6 1.2 0 .1.2.2.4.1.6-2.5-.1-2.7 6.1-6.7 2.1 1.5-.8 3-1.5 4.4-2.3z"/>
  <path fill="#6D381A" d="M103.3 9.3c-.4 1.2-1.2 1.2-2.2.7-.1-.1-.2-.2-.3-.4l2.4-.6.1.3z"/>
  <path fill="#170C09" d="M92.7 11.9h-1.6 1.6z"/>
  <path fill="#6D381A" d="M109.4 8.6c0 .7-.5.9-1.2.9-.3-.1-.5-.3-.6-.6l1.6-.6.2.3zm-9.4.7h-1.2 1.2zm-1.2 0l-.9.4c.3-.2.6-.3.9-.4z"/>
  <path fill="#6D381A" d="M107.9 9.3c0 .6-.2.8-.8.6l-.3-.3c.3-.2.5-.4.8-.6.2 0 .2.1.3.3zm7.5-1.6c-.3.2-.5.4-.8.6.2-.2.5-.4.8-.6z"/>
  <path fill="#8B5A3F" d="M81.1 155.5l5.1 2.3-2.6 1.3c-1-1.2-1.6-2.2-2.5-3.6z"/>
  <path fill="#D4824D" d="M118.6 16.3c-2-.4-4.7.4-5.2-3 2.4 1.1 4.5 2 6.6 3-.4-.1-1 0-1.4 0z"/>
  <path fill="#CF8753" d="M113.1 17.6c-1.2.6-2.5.6-3.8.1.1-.7.2-1.3.2-2 1.5.6 2.8 1.2 4.3 1.8l-.7.1z"/>
  <path fill="#9E5629" d="M109.3 17.6c1.2 0 2.5-.1 3.8-.1-1.3 0-2.5.1-3.8.1z"/>
  <path fill="#B47B4B" d="M134.3 104.7l1.5 1.2c-1.9 1.9-4 .9-6 .1h-7.7v.8c1.5.1 3 .4 4.4.5 0 .4-.1.8-.1 1.3-4.8-.2-9.7-.4-14.6-.6v-1.1c2-.1 4.1-.3 3.8-.3-.6.2-4.2-3.2-6.4.9-.1-.4-.1-.6-.2-.9-1.2-1.3-3.6.1-4.6-1.6-.1-1.1 1-1.3 1.5-1.9.2-.3.5-.5.8-.7 1 0 2.1-.1 3.1-.1 8 4.8 16.6 1 24.5 2.4z"/>
  <path fill="#673610" d="M135.9 105.9c2.2.5 4.4.9 6.7 1.3-3.2 1.2-6.4 2.4-10.6 4 1.5-1.4 2.1-2 3.6-3.5-2.4 1-3.7 1.5-4.9 2 .2-.7.5-1.4 1-2.4-1.2.4-2.1.6-3.4 1 .8-1.1 1.2-1.6 1.7-2.2 2-.2 4-.2 5.9-.2z"/>
  <path fill="#80491A" d="M143.2 98.1h13.6c.1.4.1.8.1 1.2-3.1.1-6.2.3-10.5.5 3.1.5 5.1.8 11.2 1.6-5.6.3-7.1.4-8.6.4-2.6 0-5.5.5-5.8-3.7z"/>
  <path fill="#B67C4B" d="M118.8 99.8c0-.4-.1-.6-.1-.9h3.6c1 .1 1.9.1 2.9.1-2.1.3-4.3.6-6.4.8z"/>
  <path fill="#9E6034" d="M144 197.4c.2-.1.2-.1.3-.1-.1-.1-.2 0-.3.1z"/>
  <path fill="#431907" d="M84.4 49c-.9.4-1.7.6-1.6-.8.2-3.3.5-6.5.8-9.8-.2-.2-.4-.5-.4-.8-.1-1.8.8-3.5 1.1-5.4.4-1.9 1.6-3.8.6-5.9-.2-.1-.5-.4-.7-.6.2-1.1.5-2 .8-3.3 2.9 2.5 5.7.4 8.4-.1-2 5.8-6.9 10.9-4.7 17.6.4.1.6.4.9.6.1.1.2.2.5.2.5.1.8.3 1 .7l-.1.1c.4.1.7.4.8.8 0 .2 0 .4.2.5-8.8-4.2-4.5 4.9-7.6 6.2z"/>
  <path fill="#633212" d="M83.5 38.7c-1.3-.7-2.6-1.3-4.1-2.2-3 4.7-5.9 9.4-8.8 14.1-.6.7-1.2 1.4-2.3.6 1.6-4.2 3.1-8.3 4.7-12.5 1.3-.9 2.7-1.8 4.1-2.8-2.4-4.5 4.6-5.8 4.1-9.8.8.1 1.6.4 2.4.5.2.1.3.2.5.3 1.2 2-.3 3.3-1.3 4.7-1.1 1.7-1.6 3.2.3 4.6.5.4 1.1 1 .6 1.8-.1.2-.1.4-.2.7z"/>
  <path fill="#BD743F" d="M94.8 18.3c-3.1.6-4.1-1.7-5.5-3.5 6.6-.6 13.2-1.1 19.8-1.6.6.2 1.2.4 3 .8-5 .8-8.9 1.5-12.7 2.1.5.4.5.9.1 1.3-1.1-.6-1.7-4.1-3.8-.8 0 .5-.1 1-.1 1.5-.2.4-.5.5-.8.2z"/>
  <path fill="#6C3C1C" d="M98.3 22.1c-1.8-.1-2.9-.1-4-.2l.6-3.6c.2-.1.5-.1.8-.1.7 1.2 1.5 2.4 2.6 3.9z"/>
  <path fill="#633212" d="M79.7 26.2h-2.4 2.4zm4 .7l-.3-.3.8-.7c.2 0 .5.1.8.1.2 1-.5 1-1.3.9zm-2.7-.8h-.2l-.3-.1c.2 0 .4.1.5.1z"/>
  <path fill="#221410" d="M53.7 47.5c.1-.3.2-.6.3-1 5.5-4 8.7-10.5 15.9-12.9.6-.4 1.2-.5 1.5.2.4.8-.2 1.3-.8 1.8-.5 1-1.1 1.9-1.7 2.8-12.5 9.3-14.8 21.7-6.7 34.9.6 1.1-.3 3-.5 4.5-.9-.2-2.1-.2-2.8-.7-10.6-7.5-10.5-16.7-5.1-26.7l-.1-2.9z"/>
  <path fill="#5F3C1A" d="M75.7 29.1c.5 4.2-2.3 5.4-5.1 6.6-.2-.6-.5-1.3-.8-2 1.8-1.4 3.7-2.8 5.9-4.6z"/>
  <path fill="#673610" d="M125.3 110.8h1.5-1.5z"/>
  <path fill="#DB914D" d="M105.9 16.9c-.2.5-.2 1.1-.3 1.6-2-.4-4.1-.6-6.1-1-.1-.4-.1-.9-.1-1.3 2.2.2 4.3.4 6.5.7z"/>
  <path fill="#743820" d="M106.4 194.2c-3.1-3.3.8-4.6.9-3.7.2-.9-.3 1.2-.9 3.7z"/>
  <path fill="#89502A" d="M108.3 194.9c.1-.1.2-.3.4-.4 0 .2-.1.4-.2.6l-.2-.2z"/>
  <path fill="#C99E7B" d="M100.2 165c1.3.5 2.6.9 3.9 1.4-2.3 1.9-2.8-.5-3.9-1.4zm4.6 1.6c1.1.1 2.1.3 3.1.4-1.1-.1-2.1-.3-3.1-.4zm-6.1-2.3c.3.1.7.4 1 .5-.4-.1-.7-.3-1-.5z"/>
  <path fill="#D19669" d="M104.9 9.7c-1.5 1.2-3.1 2.4-7.4 5.9 2.5-3.9 3.1-4.7 3.6-5.6.7-.2 1.5-.5 2.2-.7.6.1 1.1.2 1.6.4z"/>
  <path fill="#D69A6F" d="M107.9 9.3c.5-.2 1.1-.5 1.6-.6.4.2.8.5 1.1.7-1.5 1-3 2-6.7 4.7 2.1-2.8 2.6-3.5 3.1-4.1.4-.3.6-.5.9-.7z"/>
  <path fill="#6C3817" d="M102.6 50.6c0-.8-.1-1.5-.1-2.3.1.8.1 1.5.1 2.3z"/>
  <path fill="#985D27" d="M94.7 107.4c-.5.3-.9.3-1.4-.1-2.3-2.4-4.5-1.7-6.7.1-.3-.3-.6-.5-.9-.8-.3-1.2-.5-2.3-.8-3.5.4.5.7.9 1.1 1.4 4.6.1 9.3.1 13.9.1-3.4 0-7 .1-10.4 0-1 0-2.1.1-2.2-1.3l.8-.7 18.3.6c-.2.6-.5 1.2-.6 1.5 1.3.7 2.5 1.3 3.7 2-5 .6-10.1-2.4-14.8.7z"/>
  <path fill="#D99451" d="M84.9 95.5c0 1.1-.1 2.3-.1 3.5-.3.4-.7.8-1.5 1.9 1.2-4.8-6.3-1.6-5.2-6 2.3.4 4.8-2 6.8.6z"/>
  <path fill="#B97E4A" d="M85.9 103.9c.2-.3.4-.5.7-.6h.6l14.5 1.1c0 .4-.1.8-.1 1.3-5.2-.4-10.5-.7-15.7-1.1v-.7z"/>
  <path fill="#CF997B" d="M87.3 93.2c.7.1 1.5.1 2.2.1-.7.1-1.5 0-2.2-.1z"/>
  <path fill="#985D27" d="M86.6 103.3l-.7.6.7-.6z"/>
  <path fill="#6B3B1A" d="M24.7 104c.3.2.7.4 1 .5-.3-.2-.7-.3-1-.5z"/>
  <path fill="#652D07" d="M94.7 108c-.2.3-.4.6-.6.8-.7.7-1.4 1.4-5.2 5.5 3.1-4.9 3.8-5.9 4.4-6.9.5.1.9.1 1.4.1v.5z"/>
  <path fill="#985D27" d="M94.1 108.8c.2-.3.4-.5.6-.8-.2.3-.3.5-.6.8z"/>
  <path fill="#D99A56" d="M129 151.4c-.1-.1 0-.4.2-.5-.5-3.5-1.1-7-1.9-13 2 5.2 3.1 8.1 4.2 10.9.5 5 4.7 9.6 2 15.1-1.5-4.2-2.9-8.3-4.3-12.3-.1 0-.2-.1-.2-.2z"/>
  <path fill="#844829" d="M128.4 150.9h.6c.1.2.1.5.1.7l-.7-.7z"/>
  <path fill="#A86932" d="M123.6 145.9c-.2.2-.4.5-.5.8.2-.3.4-.5.5-.8z"/>
  <path fill="#D79856" d="M74.1 123.6c.1.6.2 1.3.2 2-2 2-3.1.4-4.4-.8-.1-.2-.1-.4-.2-.6 1.5-.2 3-.5 4.4-.6z"/>
  <path fill="#985D27" d="M85.1 107.3c.2-.2.4-.5.6-.7-.2.3-.5.5-.6.7z"/>
  <path fill="#8E4F27" d="M126.8 151.7c.2-.1.5-.1.8-.1.3 2.5.7 5.2 1 7.7-.5 0-.8.1-1.2.1-.3-2.5-.5-5.1-.6-7.7z"/>
  <path fill="#D99451" d="M46.9 91.2c1.3-.8.8-2 .8-3 .1-1.3-.5-2.8.6-4 2.1 2.1 4.2 4.3 6.7 6.9.5.2 2.1.5 3.2 1.1 1.1.6 2 1.7 3 2.5 4.6.2 9.2.5 13.8.7 1.1 1.6 2.1 3.2 3.1 4.8 0 1.1-.8 1.6-1.7 2.1-6.4 4.2-14.6 5.2-21.8-.3-.2-.1-.4-.3-.5-.5l-.2-.1.1.2.2.2c.2.1.4.3.5.4.5.4.9.7 1.5 1 .4.3.7.5 1.1.7.5.3 1 .7 1 1.3.1 3.6.2 7.3-2.9 10-.5.4-2.1-.5-4.1-1 4.1-3.9 3.8-7.2-.5-11.1v9c-7.4-7.1 5-15.7-3.9-20.9z"/>
  <path fill="#6D3314" d="M78.2 66.6c1.5-4.9 3-9.9 4.5-14.9 4.2 1.8-2.5 4.3.9 6.5.8-1.2 1.7-2.4 2.9-4.3-.4 3.5-1.8 5.4-4.6 6.3 1.5 5.4-.7 9.9-3.4 14.3.8-2.9 1.7-5.8 2.5-8.8-1.1.4-2 .7-2.8.9z"/>
  <path fill="#B66A2C" d="M70.2 55c1.1.6 2.1 1.2 4.6 2.6-4.1-.2-6.5-.4-9-.5-2.1 2.1-4.4 4.6-6.7 7.1-.2-.2-.5-.5-.8-.7 3.4-4.1 6.7-8.1 10.1-12.2l2.3-.6c-.2 1.4-.3 2.9-.5 4.3z"/>
  <path fill="#803E0B" d="M48.1 90.6c-.8-.3-.5-1.1-.9-1.6-.2-1.8-.2-3.7-.4-5.5-.2-.2-.3-.5-.5-.7-.1-.4-.1-.8-.2-1.3.2-.8.5-1.5 1.1-3.1.5 2.6.8 4.2 1.1 5.9.1 1.6.2 3.3.3 4.9.3.6.3 1.3-.5 1.4z"/>
  <path fill="#CC8D4F" d="M69.8 84.9c-.8.9-1.5 1.9-2.3 2.8-1.6-2.5 1.4-2 2.3-2.8z"/>
  <path fill="#722C05" d="M68.3 61.9c-1 .6-2.1 1.3-3.1 2-.2-.3-.5-.6-.8-.8.8-.8 1.6-1.6 2.4-2.3.6.3 1.1.8 1.5 1.1z"/>
  <path fill="#CC8D4F" d="M64.4 94.1c-3.8 0-.9-2.3-1.4-3.5.5 1.1 1 2.3 1.4 3.5z"/>
  <path fill="#6D3314" d="M82 49.8c.2-.5.5-.9.7-1.4.5.3 1.1.5 1.6.8l-2.3.6z"/>
  <path fill="#CC8D4F" d="M63 88.4c0-.5-.1-.9-.1-1.4 0 .5.1.9.1 1.4z"/>
  <path fill="#884B1E" d="M47.8 61.1c.2-.4.4-.9.5-1.4-.1.4-.4.9-.5 1.4zm.8-3.4c.2-.3.4-.6.5-.8-.2.2-.3.5-.5.8z"/>
  <path fill="#76401F" d="M95 52.7c.6-1.4-.1-2.9.5-4.3 0-.4.1-.9.2-1.3 1.4-.4 2.5.3 3.7.6-1.1 4.2 2.1 9.3-2.9 12.7-.6-2.6-1-5.1-1.5-7.7z"/>
  <path fill="#A26333" d="M94.8 44.9l3.2-.6c.4 1.1.8 2.3 1.3 3.4-1.7 1.8-2.7.6-3.7-.6-.2-.8-.6-1.5-.8-2.2zm-1.4 0c-.5 2.3-.3 1.1 0 0 .2 1.2.4 2.3 0 0zm2.1 3.4c-.2 1.4-.3 2.9-.5 4.3.2-1.4.4-2.8.5-4.3z"/>
  <path fill="#6C3817" d="M85 75.2l-1.5.6 1.5-.6z"/>
  <path fill="#D7853C" d="M73.1 78.4c-.1.1-.2.1-.2.1s.1 0 .2-.1z"/>
  <path fill="#B27640" d="M98.7 67.4v-.8c.8-1.6 1.8-3.2 2.6-4.7.3.1.6.2.9.4-.9 2-1.9 3.9-2.8 5.9-.2-.4-.4-.6-.7-.8z"/>
  <path fill="#6C3817" d="M98.7 67.3l.7.7-.7-.7z"/>
  <path fill="#9E6239" d="M102.4 58c-.8.1-1.5.1-2.1.2.2-1.3.5-2.8.7-4.2.4 1.3.9 2.7 1.4 4z"/>
  <path fill="#D58539" d="M43.8 71.5l.2.1c-1.8 8.1-3.6 16.3-5.4 24.5-.5 0-.7-.3-.8-.7-1.9-1.1-3.8-2-5.7-3 1-2.1 1.9-4 2.8-5.9 3.1-5 6-10 8.9-15z"/>
  <path fill="#32140E" d="M40.2 99.7c-3.4 1.6-3.1-1.9-4.6-2.8l2.3-1.5.8.7 1.5 3.6z"/>
  <path fill="#D58539" d="M43.8 71.5c.2-.4.2-.8-.3 1.3.9-2 .8-1.6.5-1.1l-.2-.2z"/>
  <path fill="#8F4D14" d="M53 101.8c-.5-.5-.5-1-.2-1.5l.2-.1c.3-.1.5-.1.8 0 8 7.7 16.2 5.7 24.4.1 1-.4 2-.8 5-2.2-8.1 7.5-15.5 10.5-24.7 7.3-.5-.3-1-.5-1.5-.8-.2-.1-.5-.4-.8-.5-.8-.5-1.5-1.1-2.3-1.6-.4-.3-.6-.5-.9-.7z"/>
  <path fill="#894E1F" d="M44 118c.4.2.8.4 1.1.6-1.5 2.2-2.9 4.3-4.4 6.4 1.3-2.4 2.3-4.7 3.3-7z"/>
  <path fill="#DAA577" d="M49.2 128.4c.1.2.1.4.1.6.2 1.3-.5 1.8-1.7 2.1l-2.1 2.1c-.2-.2-.4-.4-.6-.5.9-1.2 1.9-2.5 2.8-3.7.6-.1 1.1-.3 1.5-.6z"/>
  <path fill="#8A4F27" d="M54.8 121.6l-2.3 4.9-.2-.2c.8-1.7 1.5-3.3 2.2-4.9l.3.2z"/>
  <path fill="#D7853C" d="M48.7 89.2c1.2 2-.4 2-1.8 2 0-.8.1-1.6.1-2.3.6.1.7 2.7 1.7.3z"/>
  <path fill="#90512F" d="M48.6 132.1l-.9-.8c.5-.7 1.1-1.4 1.7-2.1-.3.9-.6 1.9-.8 2.9z"/>
  <path fill="#8A4F27" d="M54.4 121.2c.4-.8.7-1.8 1.1-2.6.2.1.5.2.7.3-.5.9-1 1.8-1.5 2.6l-.3-.3z"/>
  <path fill="#824B3B" d="M51.8 134.6c.6.2 1.2.5 1.8.8-.2.2-.2.4-.4.6-.6-.4-1.3-.7-1.9-1.1.2-.1.3-.3.5-.3z"/>
  <path fill="#DAA577" d="M50.6 127.7H50c.5-.5 1-.9 1.5-1.4.4.8-.2 1.2-.9 1.4z"/>
  <path fill="#7B4122" d="M52.2 126.1l.2.2c-.5.4-1.1.9-1.8 1.3.3-.5.6-.9.9-1.4.3.1.5 0 .7-.1z"/>
  <path fill="#DAA577" d="M49.2 128.4l.8-.7c-.2.3-.5.5-.8.7z"/>
  <path fill="#D7853C" d="M46.3 82.8c.2.2.3.5.5.7-.2-.3-.4-.5-.5-.7z"/>
  <path fill="#A26333" d="M98.6 42.8c1.7 1.3.4 1.3-.7 1.4.3-.5.5-.9.7-1.4z"/>
  <path fill="#CE8442" d="M91.7 42.3c-.2-.3-.5-.6-.8-.8l.8.8zM91 41.6c-.3-.2-.7-.5-1-.7.4.2.7.4 1 .7zm-1.5-1l-.9-.6.9.6z"/>
  <path fill="#D7955D" d="M84.9 25.9c.7-.5 1.4-1 2.9-2.2-1.7 5.4-3 9.8-4.4 14.1-1.5-1.2-2.9-2.5-4-3.3 1.5-2.8 2.8-5.2 4.2-7.7.5-.2 1-.6 1.3-.9z"/>
  <path fill="#D78C44" d="M52.8 100.4c.1.5.1.9.2 1.4-.1-.5-.1-1-.2-1.4zm3.3 3.6c.2.2.5.4.8.6-.2-.2-.5-.4-.8-.6zm-3.1-2.2c.2.2.5.4.8.6-.3-.1-.5-.4-.8-.6zm.8-1.6H53h.8z"/>
</svg>
  </StyledZeus>
);

export default Zeus;
